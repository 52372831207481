document.addEventListener('DOMContentLoaded', function () {
	if(!document.querySelector('input[name="brandfilter"]')) return
    var inputField = document.querySelector('input[name="brandfilter"]');
    var brandElements = document.getElementsByClassName('brand');

    inputField.addEventListener('input', function() {
        var filterValue = this.value.toLowerCase();

        for (var i = 0; i < brandElements.length; i++) {
            var brandElement = brandElements[i];
            var dataName = brandElement.getAttribute('data-name').toLowerCase();

            if (dataName.indexOf(filterValue) > -1) {
                brandElement.style.display = "";
            } else {
                brandElement.style.display = "none";
            }
        }
    });
});
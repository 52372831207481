// A $( document ).ready() block.
    
// window.addEventListener('scroll', e => { 
//     if (document.querySelector('.header--slider-akustik')) {
//         let slider = document.querySelector('.header--slider-akustik')
//         console.log(slider);
//         slider.dataset.scrolled = window.scrollY
//         slider.style.top = `${-window.scollY}px`
//     }
// })
	$(document).ready(function () {

        $('.header--slider-akustik').slick({
            autoplay: true,
            dots: true,
            arrows: false,
            autoplaySpeed: 5000,
        })

		$('.modal').on('shown.bs.modal', function (e) {
			$('.slick-slider').slick("setPosition", 0);
		});

		$('#autoplay').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3500,
			arrows: false
		  });

		$('.slider-for').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			arrows: false,
			asNavFor: '.slider-nav'
		});
		$('.slider-nav').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			asNavFor: '.slider-for',
			dots: false,
			centerMode: true,
			focusOnSelect: true,
			initialSlide: 0,
			arrows: true
		});

		var $status = $('.pagingInfo');
		var $slickElement = $('.slider-for-marken');

		$slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			if(!slick.$dots){
				return;
			}

			var i = (currentSlide ? currentSlide : 0) + 1;
			$status.text(i + '/' + (slick.$dots[0].children.length));
		});

		var $statusNews = $('.pagingInfoNews');
		var $slickElementNews = $('.news-content');

		$slickElementNews.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			if(!slick.$dots){
				return;
			}

			var i = (currentSlide ? currentSlide : 0) + 1;
			$statusNews.text(i + '/' + (slick.$dots[0].children.length));
		});

		$('.slider-for-marken').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			arrows: false,
			dots: true,
			asNavFor: '.slider-nav-marken'
		});

		$('.slider-nav-marken').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			asNavFor: '.slider-for-marken',
			dots: false,
			centerMode: true,
			focusOnSelect: true,
			initialSlide: 0,
			arrows: true,
			prevArrow: $('.prev'),
			nextArrow: $('.next'),
		});

		$('.news-content').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			centerMode: true,
			focusOnSelect: true,
			initialSlide: 0,
			arrows: true,
			prevArrow: $('.prev-news'),
			nextArrow: $('.next-news'),
		});

		$('.slider-for-sun').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			arrows: false,
			asNavFor: '.slider-nav-sun'
		});

		$('.slider-nav-sun').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			asNavFor: '.slider-for-sun',
			dots: false,
			centerMode: true,
			focusOnSelect: true,
			initialSlide: 0,
			arrows: true
		});

		// $('.main-slider').slick({
		// 	dots: true,
		// 	arrows: false,
		// 	infinite: true,
		// 	speed: 2000,
		// 	autoplaySpeed: 5000,
		// 	fade: true,
		// 	autoplay: true,
		// 	//cssEase: 'linear'
		// 	adaptiveHeight: true
		// });

        // Markenslider
		// $('#markenlogos').slick({
		// 	// centerMode: true,
		// 	// centerPadding: '0px',
        //     slidesToShow: 7,
        //     slidesToScroll: 7,
        //     infinite: true,
        //     autoplay: true,
        //     autoplaySpeed: 5000,
		// 	responsive: [{
		// 			breakpoint: 768,
		// 			settings: {
		// 				slidesToShow: 5,
        //                 slidesToScroll: 5,
        //                 centerMode: true
		// 			}
		// 		},
		// 		{
		// 			breakpoint: 480,
		// 			settings: {
		// 				slidesToShow: 3,
		// 				slidesToScroll: 3,
		// 			}
		// 		}
		// 	]
        // });
        


		$('.markenlogo__slider').slick({
			// centerPadding: '20px',
			slidesToShow: 10,
			slidesToScroll: 1,
			autoplay: true,
			infinite: true,
			autoplaySpeed: 0,
			speed: 5000,
			cssEase: 'linear',
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						arrows: false,
						slidesToShow: 6
					}
				},
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						slidesToShow: 4
					}
				},
				{
					breakpoint: 580,
					settings: {
						arrows: false,
						slidesToShow: 3
					}
				}
			]
		});

		$('.image-slider').slick({
			centerPadding: '0px',
			slidesToShow: 4,
			slidesToScroll: 4,
			autoplay: true,
			infinite: true,
			autoplaySpeed: 5000,
			responsive: [
				{
					breakpoint: 1000,
					settings: {
						arrows: true,
						centerPadding: '0px',
						slidesToScroll: 3,
						slidesToShow: 3
					}
				},
				{
					breakpoint: 768,
					settings: {
						arrows: true,
						centerPadding: '0px',
						slidesToScroll: 2,
						slidesToShow: 2
					}
				},
				{
					breakpoint: 580,
					settings: {
						arrows: true,
						centerPadding: '0px',
						slidesToScroll: 1,
						slidesToShow: 1
					}
				}
			]
		});

		$('.image-slider-akustik').slick({
			centerPadding: '0px',
			slidesToShow: 4,
			slidesToScroll: 4,
			autoplay: true,
			arrows: true,
			autoplaySpeed: 5000,
			responsive: [
				{
				breakpoint: 1000,
				settings: {
					centerPadding: '0px',
					slidesToScroll: 3,
					slidesToShow: 3
				}
			},
				{
				breakpoint: 768,
				settings: {
					centerPadding: '0px',
					slidesToScroll: 2,
					slidesToShow: 2
				}
			},
				{
					breakpoint: 580,
					settings: {
						centerPadding: '0px',
						slidesToScroll: 1,
						slidesToShow: 1
					}
				}
			]
		});



		/*
		$('.brillen-slider').slick({
		  dots: false,
		  infinite: true,
		  speed: 500,
		  cssEase: 'linear'

		});
		*/

		$('.team-slider').slick({
			dots: false,
			infinite: true,
			speed: 500,
			cssEase: 'linear'

		});

		$('.team-slider-stralsund').slick({
			dots: false,
			infinite: true,
			speed: 500,
			cssEase: 'linear'
		});

		$('.team-slider-grimmen').slick({
			dots: false,
			infinite: true,
			speed: 500,
			cssEase: 'linear'
		});

		$('.team-slider-demmin').slick({
			dots: false,
			infinite: true,
			speed: 500,
			cssEase: 'linear'
		});

		
	});

	// Image Slider

	$(function(){
		const slideshow = $('#slideshow');

		$('#slideshow > div:gt(0)').hide();

		setInterval(function () {
			$('#slideshow > div:first')
			.fadeOut(1000)
			.next()
			.fadeIn(1000)
			.end()
			.appendTo(slideshow);
		}, 4000);
	
	
		// Toggle
	
	
		$('dt').on('click', function () {
			$(this).parent().toggleClass('toggleActive');

	})




	});

// Menü Sehtrainig

if (document.getElementById('select')) {
	
	var selectBtn = document.getElementById('select');
	var selectContent = document.querySelector('.selectContent');
	var selectBox = document.querySelector('.selectBox');	
	
	selectBtn.addEventListener('click', function(){
	selectContent.classList.toggle('active')
	selectBox.classList.toggle('active')
})
 }




  
class BH_NavigationHandler { 
	constructor() { 
		this.BREAKPOINT = 1400;
		this.headerInner = ""
		this.navMenu = ""
		this.burgerIcon = ""
		this.navItems = ""
		this.subNavButtons = ""
	}

	init() { 
		this.headerInner = document.querySelector('.header-inner')
		this.burgerIcon = document.querySelector('.burger-menu')
		this.navMenu = document.querySelector('.menu')
		this.navItems = this.navMenu.querySelectorAll('li a')
		this.subNavButtons = this.navMenu.querySelectorAll('.submenu-toggle')
		this.navClickEventHandlers();
		console.log('NAV INIT')
	}
	toggleMobileNav() { 
		this.burgerIcon.classList.toggle('active')
		this.navMenu.classList.toggle('active')
	}
	navClickEventHandlers() { 
		this.burgerIcon.addEventListener('click', () => { 
			this.toggleMobileNav();
		})
		this.navItems.forEach(item => {
			item.addEventListener('click', (e) => {
				if (window.innerWidth < this.BREAKPOINT) {
					this.toggleMobileNav()
				}
			})
		})
		this.subNavButtons.forEach(button => { 
			button.addEventListener('click', (e) => {
				button.parentNode.classList.toggle('open')
			})
		})
	}

	resize() { 
		if (window.innerWidth > this.BREAKPOINT) {
			this.burgerIcon.classList.remove('active')
			this.navMenu.classList.remove('active')
		}
	}
}

const NavHandler = new BH_NavigationHandler();


document.addEventListener("DOMContentLoaded", () => { 
	NavHandler.init();
})

window.addEventListener("resize", () => { 
	NavHandler.resize();
})


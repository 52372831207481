$(".mitarbeiter .lupe").on("click", function() {
  $("#overlay").addClass("active");
  $("body").css({ overflow: "hidden" });

  let target = this.parentNode.dataset.id;
  $("#" + target + "").addClass("active");
});

$(".popupText .lupe").on("click", function() {
  $("#overlay").removeClass("active");
  $("body").css({ overflow: "auto" });
  $(".popupContent").removeClass("active");
});


// var pause = document.getElementById("audio");
// function pauseAudio() {
//   pause.pause();
// }

const pauseAudio = el => document.querySelector(el).pause() 




const openPopup = (id) => {
  const modal = document.getElementById(id)
  modal.classList.add('active')
}

const openModalPopup = id => { 
    const mod = document.getElementById('overlay')
    const modalContent = document.getElementById(id)
    mod.classList.add('active')
    modalContent.classList.add('active')
    document.body.style.overflow = 'hidden'
}
const closeModalPopup = id => { 
    const mod = document.getElementById('overlay')
    const modalContent = document.getElementById(id)
    mod.classList.remove('active')
    modalContent.classList.remove('active')
    document.body.style.overflow = 'initial'
}

const closePopup = (id) => {
  const modal = document.getElementById(id)
  modal.classList.remove('active')
}





window.addEventListener('keyup', e => {
  console.log(e.key)
    if (e.key === "Escape" || e.key === "Esc") {
        if (document.querySelector('.modal--akustik')) {
            closeModalPopup('schritt--1')
            closeModalPopup('schritt--2')
            closeModalPopup('schritt--3')
            closeModalPopup('schritt--4')
         }
  closePopup('overlay-audio')
  pauseAudio('#audio')
  closePopup('overlay-video')
  pauseAudio('#video')
 } 
})

window.addEventListener('click', e => {
  if(e.target.id === "overlay-audio"){
    closePopup('overlay-audio')
    pauseAudio('#audio')
  }
  if(e.target.id === "overlay-video"){
    closePopup('overlay-video')
    pauseAudio('#video')
  }
})

document.addEventListener("touchstart",function(){},true)




const openModal = el => { 
    document.getElementById(el).classList.add('active')
}
const closeModal = el => { 
    document.getElementById(el).classList.remove('active')
    sessionStorage.setItem('brillenhaus_modal', 'hide')
}
window.addEventListener('load', () => { 
    if (document.getElementById('modal-corona') && sessionStorage.getItem('brillenhaus_modal') !== 'hide') { 
        openModal('modal-corona')
    }

})